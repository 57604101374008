<template>
    <div>
        <Pane />
        <a-card class="container">
            <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" :colon="false" :form="form">
                <a-row>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="项目名称">
                            <a-input v-decorator="[
                                'name',
                                {
                                    initialValue: detail.name,
                                    rules: [
                                        { required: true, message: '请输入！' },
                                    ],
                                },
                            ]" placeholder="请输入" style="width: 200px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="开标日期">
                            <a-date-picker v-decorator="[
                                'date',
                                {
                                    initialValue: detail.date,
                                    rules: [
                                        { required: true, message: '请输入！' },
                                    ],
                                },
                            ]" style="width: 200px" />
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="中标情况">
                            <a-select v-decorator="[
                                    'status',
                                    {
                                        initialValue: detail.status,
                                    }
                                ]" placeholder="请输入" style="width: 200px">
                                <a-select-option
                                    v-for="item in status"
                                    :key="item.value"
                                    :value="item.value"
                                >{{ item.name }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="中标金额(万元)">
                            <a-input type="number" v-decorator="[
                                'amount',
                                {
                                    initialValue: detail.amount,
                                }
                            ]" placeholder="请输入" style="width: 200px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="未中标原因">
                            <a-input v-decorator="[
                                'remark',
                                {
                                    initialValue: detail.remark,
                                }
                            ]" placeholder="请输入" style="width: 200px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="生产部门">
                            <a-select v-decorator="[
                                    'deptId',
                                    {
                                        initialValue: detail.deptId,
                                        rules: [
                                            { required: true, message: '请输入！' },
                                        ],
                                    }
                                ]" placeholder="请输入" 
                                show-search
                                style="width: 200px"
                                :filter-option="$selectFilterOption">
                                <a-select-option
                                v-for="item in organizationList"
                                :key="item.id"
                                :value="item.id"
                                >{{ item.uniqueName }}</a-select-option
                                >
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>

                <div class="center">
                    <a-space>
                        <a-button @click="$close($route.path)">关闭</a-button>
                        <a-button type="primary" :loading="loading" @click="handleSubmit">保存</a-button>
                    </a-space>
                </div>
            </a-form>
        </a-card>
    </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import request from "@/api/request";
import organization from "@/mixins/organization";
function save(data) {
    return request({
        url: "project-service/dsBid/update",
        method: "post",
        data
    });
}
function getDetail(id) {
  return request({
    url: "project-service/dsBid/detail/" + id,
  });
}
export default {
    name: "technicalQualityScoreAdd",
    mixins: [organization],
    computed: {
        ...mapGetters("setting", ["findDataDict"]),
        ...mapGetters("auth", ["isHr"]),
        status() {
            return this.findDataDict("ds_bid");
        },
    },
    data() {
        return {
            form: this.$form.createForm(this),
            loading: false,
            detail: {}
        };
    },
    mounted() {
        const { query } = this.$route;
        const { id } = query || {};
        getDetail(id).then(res => {
            this.detail = res;
        });
    },
    methods: {
        hanldeSave() {

        },
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    this.loading = true;
                    const { query } = this.$route;
                    const { id } = query || {};
                    save({
                        id: id,
                        amount: values.amount,
                        code: values.code,
                        date: values.date,
                        deptId: values.deptId,
                        deptName: this.organizationList.filter(v=>v.id == values.deptId)[0].uniqueName,
                        name: values.name,
                        remark: values.remark,
                        status: values.status,
                    })
                        .then(() => {
                            this.$close(this.$route.path);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        }
    },
};
</script>
  
  
<style lang="less" scoped>
.container {
    padding: 12px;
}

.center {
    margin-top: 80px;
    margin-bottom: 80px;
}
</style>